import { TFunction } from "next-i18next";

import { BackgroundTypes } from './app';
import { UserFlags } from './user';

export enum RedirectType {
  NewTab = 'newTab',
  CurrentWindow = 'currentWindow',
}

export type Page = {
  path: string;
  securedExternalRoute?: string;
  title: string;
  parent?: string;
  parentTitle?: string;
  icon?: string;
  background: BackgroundTypes;
  section: string;
  isVisible: boolean;
  children?: Array<Page>;
  redirectType?: RedirectType;
};

export enum PageSections {
  QuickLinks = 'pageSections.quickLinks',
  Account = 'pageSections.account',
  UnGrouped = '',
  PowerTools = 'pageSections.powerTools',
}
export const routes = {
  accountSettings: '/accountSettings',
  ach: '/ACH',
  batchDetails: '/reports/batchesAndTransactions/[batchNumber]',
  batchesAndTransactions: '/reports/batchesAndTransactions',
  benefitsAdministration: '/benefitsAdministration',
  billPay: '/billPay',
  checkout: '/buyEquipment/checkout',
  documents: '/documents',
  equipment: '/buyEquipment',
  equipmentDetails: '/buyEquipment/[name]',
  funding: '/reports/funding',
  hiringAndOnboarding: '/hiringAndOnboarding',
  home: '/',
  impersonateUser: '/impersonateUser',
  inPerson: '/inPerson',
  loans: '/loans',
  paymentsPlus: '/paymentsPlus',
  payrollPlus: '/payroll',
  permissionOverride: '/permissionOverride',
  pointOfSale: '/pos',
  reports: '/reports',
  restaurant: '/restaurant',
  retail: '/retail',
  sgaSimulator: '/sgaSimulator',
  statementReport: '/reports/statement',
  store: '/store',
  support: '/support',
  timeAndAttendance: '/timeAndAttendance',
  userManagement: '/users',
};

export const externalRoutes = {
  billPayMerchantView: 'Bill Pay',
  infoCentral: 'Info Central',
  infoCentralBatchesReports: 'Info Central Batches Reports',
  infoCentralFundingReports: 'Info Central Funding Reports',
  infoCentralStatementsReports: 'Info Central Statements Reports',
  infoCentralTransactionReports: 'Info Central Transactions Reports',
  paymentsDashboard: 'Payments Dashboard',
  payrollBenefitsAdministration: 'Benefits Administration',
  payrollHiringAndBoarding: 'Hiring & Onboarding',
  payrollPlus: 'Payroll+',
  payrollTimeAndAttendance: 'Time & Attendance',
}

export const pageTitles = {
  accountSettings: 'pageTitles.accountSettings',
  achReporting: 'pageTitles.achReporting',
  batchesAndTransactions: 'pageTitles.batchesAndTransactions',
  billPay: 'pageTitles.billPay',
  capital: 'pageTitles.capital',
  checkout: 'pageTitles.checkout',
  documents: 'pageTitles.documents',
  equipment: 'pageTitles.equipment',
  funding: 'pageTitles.funding',
  helpAndSupport: 'pageTitles.helpAndSupport',
  home: 'pageTitles.home',
  impersonateUser: 'pageTitles.impersonateUser',
  inPerson: 'pageTitles.inPerson',
  integrations: 'pageTitles.integrations',
  support: 'pageTitles.support',
  paymentManagerPlus: 'pageTitles.paymentManagerPlus',
  paymentsPlus: 'pageTitles.paymentsPlus',
  payroll: 'pageTitles.payroll',
  payrollBenefitsAdministration: 'pageTitles.payrollBenefitsAdministration',
  payrollHiringAndBoarding: 'pageTitles.payrollHiringAndBoarding',
  payrollPlus: 'pageTitles.payrollPlus',
  payrollTimeAndAttendance: 'pageTitles.payrollTimeAndAttendance',
  permissionOverride: 'pageTitles.permissionOverride',
  pointOfSale: 'pageTitles.pointOfSale',
  reports: 'pageTitles.reports',
  restaurant: 'pageTitles.restaurant',
  retail: 'pageTitles.retail',
  sgaSimulator: 'pageTitles.sgaSimulator',
  statementReport: 'pageTitles.statementReport',
  userManagement: 'pageTitles.userManagement',
}

export const marketLandingPageRoutes = {
  achReport: '/ach-report',
  billPay: '/bill-pay',
  inPerson: '/payment-processing',
  lending: '/lending',
  paymentsManagerPlus: '/payments-manager',
  payrollPlus: '/payroll-plus',
  retail: '/retail-pos',
  restaurant: '/restaurant-pos',
  shoppingCart: '/shopping-cart',
}

export const unauthorizedRoute = '/unauthorized';
export const notFoundRoute = '/404';
const commonNs = 'common';

export const SetPageTitle = (route: string, getTranslation: TFunction, flags?: UserFlags) => {
  let pageTitle = route;
  const pages = GetApplicationPages(getTranslation, flags);
  pages.forEach((page) => {
    if(page.path === route) {
      pageTitle = page.title;
    } else {
      page.children?.forEach((child) => {
        if(child.path === route) {
          pageTitle = child.title;
        }
      });
    }
  });

  return `MyAccount | ${pageTitle}`;
};

const seeAllUserFlags: UserFlags = {
  showFundingReport: true,
  showIntegrations: true,
  showHiringAndOnBoarding: true,
  showTimeAndAttendance: true,
  showBenefitsAdministration: true,
  showOnlineStore: true,
  showBillPay: true,
  showAccountSettings: true,
  showSGASimulator: true,
  showCTACard: true,
  showPermissionOverride: true,
  showImpersonateUser: true,
  showStatementReport: true,
};

//For Permissions, see Routes.
export function GetApplicationPages(t: TFunction, flags: UserFlags = seeAllUserFlags) {
  const getTranslation = (name: string) => t(name, { ns: commonNs });
  const ApplicationPages: Array<Page> = [
    {
      path: routes.home,
      title: getTranslation(pageTitles.home),
      icon: 'fa-regular fa-house',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.paymentsPlus,
      title: getTranslation(pageTitles.paymentsPlus),
      icon: 'fa-regular fa-money-bill-transfer',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
      children: [
        {
          path: routes.paymentsPlus,
          securedExternalRoute: externalRoutes.paymentsDashboard,
          title: getTranslation(pageTitles.paymentManagerPlus),
          parent: routes.paymentsPlus,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: true,
          redirectType: RedirectType.CurrentWindow,
        },
        {
          path: routes.store,
          title: getTranslation(pageTitles.integrations),
          parent: routes.paymentsPlus,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showIntegrations,
        },
        {
          path: routes.billPay,
          title: getTranslation(pageTitles.billPay),
          parent: routes.paymentsPlus,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showBillPay,
        },
        {
          path: routes.inPerson,
          title: getTranslation(pageTitles.inPerson),
          parent: routes.paymentsPlus,
          parentTitle: getTranslation(pageTitles.paymentsPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: true,
        },
      ],
    },
    {
      path: routes.pointOfSale,
      title: getTranslation(pageTitles.pointOfSale),
      icon: 'fa-regular fa-credit-card',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
      children: [
        {
          path: routes.restaurant,
          title: getTranslation(pageTitles.restaurant),
          parent: routes.pointOfSale,
          background: BackgroundTypes.Default,
          parentTitle: getTranslation(pageTitles.pointOfSale),
          isVisible: true,
          section: getTranslation(PageSections.QuickLinks),
        },
        {
          path: routes.retail,
          title: getTranslation(pageTitles.retail),
          parent: routes.pointOfSale,
          background: BackgroundTypes.Default,
          parentTitle: getTranslation(pageTitles.pointOfSale),
          isVisible: true,
          section: getTranslation(PageSections.QuickLinks),
        },
      ],
    },
    {
      path: routes.payrollPlus,
      title: getTranslation(pageTitles.payrollPlus),
      icon: 'fa-regular fa-hand-holding-dollar',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
      children: [
        {
          path: routes.payrollPlus,
          securedExternalRoute: externalRoutes.payrollPlus,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          title: getTranslation(pageTitles.payroll),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showBenefitsAdministration || flags.showHiringAndOnBoarding || flags.showTimeAndAttendance,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.hiringAndOnboarding,
          securedExternalRoute: externalRoutes.payrollHiringAndBoarding,
          parentTitle: getTranslation(pageTitles.payrollPlus),
          title: getTranslation(pageTitles.payrollHiringAndBoarding),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showHiringAndOnBoarding,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.timeAndAttendance,
          securedExternalRoute: externalRoutes.payrollTimeAndAttendance,
          title: getTranslation(pageTitles.payrollTimeAndAttendance),
          parentTitle: getTranslation(pageTitles.payrollPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showTimeAndAttendance,
          redirectType: RedirectType.NewTab,
        },
        {
          path: routes.benefitsAdministration,
          securedExternalRoute: externalRoutes.payrollBenefitsAdministration,
          title: getTranslation(pageTitles.payrollBenefitsAdministration),
          parentTitle: getTranslation(pageTitles.payrollPlus),
          background: BackgroundTypes.Default,
          section: getTranslation(PageSections.QuickLinks),
          isVisible: flags.showBenefitsAdministration,
          redirectType: RedirectType.NewTab,
        },
      ],
    },
    {
      path: routes.loans,
      title: getTranslation(pageTitles.capital),
      icon: 'fa-regular fa-sack-dollar',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.reports,
      title: getTranslation(pageTitles.reports),
      icon: 'fa-regular fa-chart-mixed',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.userManagement,
      title: getTranslation(pageTitles.userManagement),
      icon: 'fa-regular fa-id-badge',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true,
    },
    {
      path: routes.equipment,
      title: getTranslation(pageTitles.equipment),
      icon: 'fa-regular fa-basket-shopping',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true,
    },
    {
      path: routes.equipmentDetails,
      title: getTranslation(pageTitles.equipment),
      parent: routes.equipment,
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true,
    },
    {
      path: routes.checkout,
      title: getTranslation(pageTitles.checkout),
      parent: routes.equipment,
      parentTitle: getTranslation(pageTitles.equipment),
      icon: 'cart',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true,
    },
    {
      path: routes.batchesAndTransactions,
      title: getTranslation(pageTitles.batchesAndTransactions),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.statementReport,
      title: getTranslation(pageTitles.statementReport),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.funding,
      title: getTranslation(pageTitles.funding),
      parent: routes.reports,
      parentTitle: getTranslation(pageTitles.reports),
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.batchDetails,
      title: getTranslation(pageTitles.batchesAndTransactions),
      parent: routes.batchesAndTransactions,
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.QuickLinks),
      isVisible: true,
    },
    {
      path: routes.documents,
      title: getTranslation(pageTitles.documents),
      icon: 'fa-regular fa-memo',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true,
    },
    {
      path: routes.accountSettings,
      title: getTranslation(pageTitles.accountSettings),
      icon: 'fa-regular fa-gear',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: flags.showAccountSettings,
    },
    {
      path: routes.support,
      title: getTranslation(pageTitles.helpAndSupport),
      icon: 'fa-regular fa-life-ring',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.Account),
      isVisible: true
    },
    {
      path: routes.sgaSimulator,
      title: getTranslation(pageTitles.sgaSimulator),
      icon: 'fa-regular fa-alien',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisible: flags.showSGASimulator,
    },
    {
      path: routes.permissionOverride,
      title: getTranslation(pageTitles.permissionOverride),
      icon: 'fa-regular fa-masks-theater',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisible: flags.showPermissionOverride,
    },
    {
      path: routes.impersonateUser,
      title: getTranslation(pageTitles.impersonateUser),
      icon: 'fa-regular fa-person-to-portal',
      background: BackgroundTypes.Default,
      section: getTranslation(PageSections.PowerTools),
      isVisible: flags.showImpersonateUser,
    },
  ];

  return ApplicationPages.filter((x) => x.isVisible);
}
